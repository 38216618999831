@import url('https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap');

@font-face {
    font-family: 'yugo-regular';
    src: URL('./resources/fonts/YuGothR.ttc')
  }

@font-face {
    font-family: 'yugo-bold';
    src: URL('./resources/fonts/YuGothB.ttc')
  }

@font-face {
    font-family: 'yugo-light';
    src: URL('./resources/fonts/YuGothL.ttc')
  }

@font-face {
    font-family: 'yugo-medium';
    src: URL('./resources/fonts/YuGothM.ttc')
}

body, html {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

:root {
    --design-orange: #6ebfa0;
    --design-cream: #f6f6f6;
    --design-yellow: #febf32;
    --design-purple: #82175d;
    --design-blue: #004c54;
    --design-green: #6ebfa0;
    --design-green-light: #6ebfa028;
    --design-gray: #e9e8e8;

    --font-family: 'Onest';
    --font-xlarge: 50px;
    --font-large: 30px;
    --font-xmedium: 26px;
    --font-medium: 22px;
    --font-small: 20px;
    --font-xsmall: 16px;

    --max-width: 1300px;
    --side-padding: 80px;

    --border-end-of-section: solid 3px var(--design-orange);
}

@media (max-width: 1200px){
    :root {
        --side-padding: 30px;
        --font-xlarge: 40px;
        --font-large: 25px;
        --font-xmedium: 22px;
        --font-medium: 18px;
        --font-small: 16px;
    }
}

* {
    font-family: var(--font-family);
}

a {
    text-decoration: none !important;
    cursor: pointer;
}

h2 {
    font-size: 80px;
    color: var(--design-blue);
}

body{
    margin: 0;
    background-color: var(--design-cream);
}

@media (max-width: 800px){
    :root {
        --side-padding: 30px;
    }
}

input, textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 5px;
    font-family: var(--font-family);
}

textarea {
    resize: none;
}
  
button {
    background-color: var(--design-blue);
    color: var(--design-cream);
    font-family: var(--font-family);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
  
.error {
    font-family: var(--font-family);
    color: red;
    margin-top: 10px;
    font-size: 0.8em;
}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  opacity: 0; /* Start invisible */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.fade-in-up.show {
  opacity: 1;
  animation: fadeInUp 0.5s ease-out forwards;
}

